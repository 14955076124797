<template>
  <v-app>
    <v-app-bar app>
      <div
        class="d-flex align-center pr-5"
        style="width: 100%; height: 100%"
      >
        <div v-if="!!user.id" style="cursor: pointer" @click="$router.push({name: 'Home'})">
          <v-img
            src="@/assets/ermetix_logo.png"
            max-height="100%"
            max-width="220"
            class="mb-3"
          ></v-img>
        </div>
        <v-spacer></v-spacer>
        <v-text-field
          id="globalSearchBar"
          v-if="!!user.id"
          v-model="searchText"
          hide-details="auto"
          prepend-inner-icon="mdi-magnify"
          height="30"
          style="max-width: 25px;"
          @focus="isSearchBarLarge = true"
          @blur="!searchText ? isSearchBarLarge = false : isSearchBarLarge = true"
          :label="getTranslation('INSERT_AT_LEAST_4_CHARACTERS')"
          :class="{
            'search-bar-fade-in': isSearchBarLarge
          }"
        >
        </v-text-field>
        <div v-if="!!user.id" class="divider"></div>
        <v-menu
          v-if="!!user.id"
          bottom
          min-width="200px"
          rounded
          offset-y
          :close-on-content-click="true"
          @input="burgerIconClicked"
        >
          <template v-slot:activator="{ on }">
            <v-icon
              style="cursor: pointer"
              v-on="on"
              :class="{
                'opened-icon': iconMenu === 'mdi-menu',
                'closed-icon': iconMenu === 'mdi-menu-open'
              }"
            >
              {{ iconMenu }}
            </v-icon>
          </template>
          <v-card style="border-color: #63adf2; border-style: solid;">
            <v-list-item-content class="justify-center">
              <div class="mx-auto text-center">
                <div>
                  <v-btn
                    depressed
                    rounded
                    text
                    @click="$router.push({name: 'handleDevices'}).catch(()=>{})"
                    style="width: 90%"
                  >
                    {{ getTranslation('HANDLE_DEVICES') }}
                  </v-btn>
                </div>
                <div v-if="!!user?.rolesUser?.accessLogsPermission || !!user?.rolesUser?.systemLogsPermission">
                  <v-divider class="my-3"></v-divider>
                  <v-btn
                    depressed
                    rounded
                    text
                    @click="$router.push({name: 'logs'}).catch(()=>{})"
                    style="width: 90%"
                  >
                    {{ getTranslation('LOGS') }}
                  </v-btn>
                </div>
                <div v-if="user.isAdmin">
                  <v-divider class="my-3"></v-divider>
                  <v-btn
                    depressed
                    rounded
                    text
                    @click="$router.push({name: 'distributorsHome'}).catch(()=>{})"
                    style="width: 90%"
                  >
                    {{ getTranslation('LIST_DISTRIBUTORS') }}
                  </v-btn>
                </div>
                <div v-if="user.isAdmin">
                  <v-divider class="my-3"></v-divider>
                  <v-btn
                    depressed
                    rounded
                    text
                    @click="$router.push({name: 'SuperAdminHome'}).catch(()=>{})"
                    style="width: 100%"
                  >
                    {{ getTranslation('SUPER_ADMIN_HOME') }}
                  </v-btn>
                </div>
                <div v-if="user.isAdmin || user?.rolesUser?.iamPermission">
                  <v-divider class="my-3"></v-divider>
                  <v-btn
                    depressed
                    rounded
                    text
                    @click="$router.push({name: 'handleUsers'}).catch(()=>{})"
                    style="width: 90%"
                  >
                    {{ getTranslation('HANDLE_USERS') }}
                  </v-btn>
                </div>
                <div v-if="user?.rolesUser?.configurationPermission">
                  <v-divider class="my-3"></v-divider>
                  <v-btn
                    depressed
                    rounded
                    text
                    @click="$router.push({name: 'configurations'}).catch(()=>{})"
                    style="width: 90%"
                  >
                    {{ getTranslation('CONFIGURATIONS') }}
                  </v-btn>
                </div>
              </div>
            </v-list-item-content>
          </v-card>
        </v-menu>
        <v-menu
          v-if="!!user.id"
          bottom
          min-width="200px"
          rounded
          offset-y
          :close-on-content-click="true"
        >
          <template v-slot:activator="{ on }">
            <v-avatar
              color="primary"
              size="40"
              class="ml-5"
              style="cursor:pointer"
              v-on="on"
            >
              <span class="white--text">{{ user.username.substring(0, 2).toUpperCase() }}</span>
            </v-avatar>
          </template>
          <v-card style="border-color: #63adf2; border-style: solid;">
            <v-list-item-content class="justify-center">
              <div class="mx-auto text-center">
                <v-avatar
                  color="primary"
                  class="mb-1"
                >
                  <span class="white--text text-h5">{{ user.username.substring(0, 2).toUpperCase() }}</span>
                </v-avatar>
                <h3>{{ user.username.toUpperCase() }}</h3>
<!--                <p class="text-caption mt-1">
                  {{ user.distributorName }}
                </p>-->
                <v-divider class="my-3"></v-divider>
                <v-btn
                  depressed
                  rounded
                  text
                  @click="$router.push({name: 'profile'}).catch(()=>{})"
                  style="width: 90%"
                >
                  {{ getTranslation('PROFILE') }}
                </v-btn>
                <v-divider class="my-3"></v-divider>
                <v-btn
                  depressed
                  rounded
                  text
                  @click="logout"
                  style="width: 90%"
                >
                  Logout
                </v-btn>
                <v-divider class="my-3"></v-divider>
                <div class="d-flex align-center justify-center">
                  <LanguageSelector />
                  <v-switch v-if="appMod === 'development'" prepend-icon="mdi-theme-light-dark" class="ml-5"  @click="toggleTheme"/>
                </div>
              </div>
            </v-list-item-content>
          </v-card>
        </v-menu>
      </div>

    </v-app-bar>
    <v-main>
      <router-view/>
      <GlobalSearchDialog
        v-if="!!isSearchBarLarge"
        @close="isSearchBarLarge = false; searchText = ''; childSearchText=''"
        :search-text="childSearchText"
        style="z-index: 999; position: absolute; top: 0; min-height: 100%; min-width:100%"
      />
    </v-main>

  </v-app>
</template>

<script>
import axios from "axios";
import translateService from "@/globalServices/translate";
import GlobalSearchDialog from "@/components/globalComponents/GlobalSearchDialog.vue";
import LanguageSelector from "@/components/LanguageSelector.vue";
import utilsFunctions from "@/globalServices/utilsFunctions";


export default {
  name: "App",

  components: {
    LanguageSelector,
    GlobalSearchDialog,
  },
  data() {
    return {
      languages: [
        {name: "it", image: require("@/assets/images/italyFlag.jpeg")},
        {name: "en", image: require("@/assets/images/ukFlag.png")},
        {name: "de", image: require("@/assets/images/germanyFlag.jpeg")},
        {name: "es", image: require("@/assets/images/spainFlag.jpeg")},
      ],
      loadingButton: false,
      iconMenu: 'mdi-menu',
      searchIconClicked: false,
      isSearchBarLarge: false,
      searchText:'',
      childSearchText: '',
      appMod: process.env.VUE_APP_MOD,
    };
  },
  mounted() {
  },
  methods: {
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    async logout() {
      try {
        this.loadingButton = true;
        axios.defaults.withCredentials = true;
        const response = await axios.post(
          process.env.VUE_APP_BASEURL + "/api/user/logout");
        if (response.status === 204) {
          this.$store.commit('setCredentials', null);
          await this.$router.push({name: 'Login'});
        }
        this.loadingButton = false;
      } catch (error) {
        this.loadingButton = false;
        utilsFunctions.handleServerResponseException(error);
      }
    },
    burgerIconClicked() {
      if (this.iconMenu === 'mdi-menu')
        this.iconMenu = 'mdi-menu-open';
      else
        this.iconMenu = 'mdi-menu';
    },
    searchTextChanged(newVal){
      this.childSearchText = this.searchText;
/*      if(!newVal){
        this.isSearchBarLarge = false;
        document.getElementById('globalSearchBar').blur();
      }*/
    },
    getTranslation(key) {
      let result = translateService.getTranslation(this.languageChosen, key);
      if (!result)
        return key;
      else
        return result
    },
  },
  watch:{
    searchText(newVal){
      this.$nextTick(() => this.searchTextChanged(newVal))
    }
  },
  computed: {
    user() {
      return this.$store.state.userModule.userCredentials
    },
    languageChosen() {
      return this.$store.state.language;
    }
  }
};
</script>

<style>
.divider {
  width: 2px;
  background-color: #ccc;
  height: 50%;
  margin-right: 20px;
  margin-left: 20px;
}

.closed-icon {
  transform-origin: center;
  transform: rotate(-90deg);
}
.opened-icon{
  transform-origin: center;
  transform: rotate(180deg);
}
.search-bar-fade-in {
  animation: fadeInRightToLeft 0.7s forwards;
  transform-origin: 100% 50%;
  max-width: 25% !important;
}

@keyframes fadeInRightToLeft {
  0% {
    transform: scaleX(0%);
  }
  100% {
    transform: scaleX(100%);
  }
}
</style>
